module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Southwater village preschools website","short_name":"SVPS","start_url":"/","background_color":"#eff0f3","theme_color":"#eff0f3","display":"standalone","icon":"src/images/icon2.svg","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e7be1142a362168097356223227d78c2"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
